<template>
    <div class="container">
        <qy-top-nav title="退货申请" />
        <van-form @submit="onSubmit" validate-first>
            

            <van-field
                    v-model="form.name"
                    required
                    name="联系人"
                    label="联系人"
                    autofocus
                    autocomplete="off"
                    :rules="[{ required: true, message: '请填写联系人' }]"
            />
            <van-field
                    v-model="form.phone"
                    type="tel"
                    required
                    maxlength="11"
                    name="联系电话"
                    label="联系电话"
                    autocomplete="off"
                    :rules="[{pattern, message: '请输入正确电话号码' }]"
            />
            
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                />
            </van-popup>

            <van-field v-model="form.remark" rows="2" autosize label="" type="textarea" maxlength="50" placeholder="请输入问题的详细描述..." show-word-limit />
            <van-field name="uploader" label="">
                <template #input>
                    <van-uploader v-model="images" upload-text="添加图片"  :after-read="afterRead" :before-delete="beforeDelete" max-count="3" multiple/>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">退货</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { Toast } from 'vant';
    export default {
        name: "goodsReturn",
        data() {
            return {
                pattern:/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
                images: [],
                columns:[
                    '质量问题',
                    '物流问题',
                    '使用问题',
                ],
                value: '',
                showPicker: false,
                form: {
                    goods:{
                        name:this.$route.params.goodsName,
                        logo:this.$route.params.goodsLogo,
                    },
                    orderId: this.getUrlParam("orderId"),
                    // orderDetailId: this.$route.params.orderDetailId,
                    // shopId: this.$route.params.shopId,
                    // goodsId: this.$route.params.goodsId,
                    // goodsInstanceId: this.$route.params.goodsInstanceId,
                    name:this.$route.params.consigneeName,
                    phone:this.$route.params.consigneePhone,
                    remark:'',
                    img:''
                },
            };
        },
        methods:{
            afterRead(file) {
                let url = "/api/common/upload";
                let fd = new FormData;
                fd.append("file", file.file);
                this.$axios.post(url, fd).then((res) => {
                    let data = res.data;
                    if (data.code == 0) {
                        file.file.url = data.fileName;
                    } else {
                        Toast.fail("上传失败");
                    }
                }).catch(err => {
                    Toast.fail(err);
                });
            },
            beforeDelete(file){
                this.images.forEach((item,index) => {
                   if (file.file.url == item.file.url){
                       this.images.splice(index,1);
                   }
                });
            },
            onConfirm(value) {
                this.value = this.form.questionType = value;
                this.showPicker = false;
            },

            onSubmit(values){
                let tempArray = [];
                this.images.forEach(file => {
                    tempArray.push(file.file.url);
                });
                if (tempArray.length > 0){
                    this.form.img = tempArray.join(',');
                }
                this.Dialog.confirm({
                    message: "确定要提交退货申请吗?"
                }).then(() => {
                    request.post("/api/order/returnGoods", this.form).then(res => {
                        if (res.success) {
                            this.$router.go(-1);
                        } else {
                            Toast.fail(res.msg || '提交失败');
                        }
                    });
                });

            }
        }
    }
</script>

<style scoped>
div.container{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}
.goods-info{
    padding: 10px 16px
}
</style>